<template>
  <div>
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="wrap">
      <div class="varietyListWrap">
        <div class="totalWrap">
          共有 <span class="num">{{ total }}</span> 个相关结果
        </div>
        <div class="varietyList">
          <div class="varietyItem" v-for="item in list_one" :key="item.id">
            <div class="name" @click="$router.push({ path: '/varietyDetail', query: { id: item.id } })">{{ item.breedName }}</div>
            <el-row>
              <el-col :span="16">申请号：{{ item.appCode }}</el-col>
              <el-col :span="8">公告日：{{ item.pubDate }}</el-col>
            </el-row>
            <el-row>
              <el-col :span="16">品种权人：{{ item.owner }}</el-col>
              <el-col :span="8">品种权号：{{ item.ownerNo }}</el-col>
            </el-row>
          </div>
        </div>
        <nf-pagination :total="total" :currentPage="currentPage" @pageChange="pageChange"></nf-pagination>
      </div>
      <div class="rightWrap">
        <hot-demand></hot-demand>
      </div>
    </div>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import nfPagination from '@/component/pagination/index';
import hotDemand from '@/component/hotDemand/index';

export default {
  name: 'varietyDetail',
  data() {
    return {
      breadList: [{ name: '热点功能' }, { name: '品种权检索' }], // 导航
      list_one: [], // 品种权列表
      list_two: [], // 实验室列表
      total: 10000,
      currentPage: Number(this.$route.query.pageNum) || 1,
      params: {
        pageNum: 1,
        pageSize: 8,
        queryName: this.$route.query.queryName || ''
      }
    };
  },
  methods: {
    // 品种权列表
    async getList_one() {
      this.params.pageNum = this.$route.query.pageNum || 1;
      const api = this.$fetchApi.varietyList.api;
      const data = await this.$fetchData.fetchPost(this.params, api, 'json');
      if (data.code === '200') {
        this.list_one = data.result;
        this.total = data.result.length;
      }
    },
    // 实验室列表
    async getList_two() {},
    // 页码变化
    async pageChange(page) {
      const pageNum = page.page_index;
      this.$router.push({
        query: merge(this.$route.query, {
          pageNum
        })
      });
      this.getList_one();
    }
  },
  created() {
    this.getList_one();
    this.getList_two();
  },
  components: { nfBreadcrumb, nfPagination, hotDemand }
};
</script>

<style lang="less" scoped>
.wrap {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  .varietyListWrap {
    width: 800px;
    .totalWrap {
      background: #fafafa;
      height: 38px;
      line-height: 38px;
      padding-left: 40px;
      box-sizing: border-box;
      margin-top: 20px;
      .num {
        color: #265ae1;
      }
    }
    .varietyItem {
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px dashed #ccc;
      .name:hover {
        color: #265ae1;
      }
      .name {
        position: relative;
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 10px;
        letter-spacing: 1px;
        cursor: pointer;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 12px;
          background: #265ae1;
          border-radius: 3px;
          position: absolute;
          left: -15px;
          top: 5px;
        }
      }
      .el-row {
        padding-top: 5px;
      }
    }
  }
  .rightWrap {
    position: absolute;
    width: 360px;
    top: 0;
    right: 0;
    .rightItem {
      border: 1px solid #d9d9d9;
      padding: 20px;
      margin-bottom: 20px;
      .titleWrap {
        border-left: 6px solid #265ae0;
        height: 30px;
        line-height: 30px;
        font-weight: 700;
        font-size: 16px;
        padding-left: 15px;
      }
    }
    .bottomList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      div {
        width: 120px;
        height: 36px;
        box-sizing: border-box;
        border: 1px solid #448afc;
        line-height: 36px;
        text-align: center;
        border-radius: 4px;
        margin-top: 20px;
      }
      div:hover {
        background: #448afc;
        color: #fff;
      }
    }
    .topList {
    }
  }
}
</style>
